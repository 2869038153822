import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/workspace/src/components/MainLayout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Metodo`}</h2>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`Eredi Monticelli's `}<a parentName="p" {...{
            "href": "/en/products"
          }}>{`wood veneers`}</a>{` are distinguishable by the high quality conferred on them by a unique and traditional method of production.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Each tree is carefully chosen by us personally. After a conditioning process with steam, the logs are transformed into veneers of different thicknesses. The quality of the cut is excellent. The sheets are allowed to dry naturally without the use of forced air. This long, natural process gives the veneer sheets a superior quality of fullness, malleability and strength. In addition, it ensures the natural colour of the wood remains intact.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`We have a wide range of wood veneers in 0.6 mm, 1 mm and 2 mm thickness available for immediate delivery at all times. However, we are happy to produce veneers sheets in different thickesses to satisfy specific requests.`}</p>
      </li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      